import CustomEventEmitter, { Dispatcher } from './CustomEventEmitter';

type CustomEventsFactory<CustomEvents extends Record<string, unknown>> = {
  [Key in keyof CustomEvents]: (value: CustomEvents[Key]) => void;
};

export type StoreEventsFactory<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
> = CustomEventsFactory<CustomEventsKeys> & {
  [Key in keyof State]: (value: State[Key]) => void;
};

type DispatcherWithKeys<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
> = Dispatcher<StoreEventsFactory<State, CustomEventsKeys>>;

export type Store<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
> = {
  dispatch: DispatcherWithKeys<State, CustomEventsKeys>['emit'];
  getState: () => State;
  off: DispatcherWithKeys<State, CustomEventsKeys>['off'];
  on: DispatcherWithKeys<State, CustomEventsKeys>['on'];
  onAny: DispatcherWithKeys<State, CustomEventsKeys>['onAny'];
  once: DispatcherWithKeys<State, CustomEventsKeys>['once'];
  reset: () => void;
};

function createStore<
  State extends Record<string, unknown>,
  CustomEventsKeys extends Record<string, unknown> = {},
>(initialState: State, namespace = 'store'): Store<State, CustomEventsKeys> {
  let state = { ...initialState };
  type StoreEvents = StoreEventsFactory<State, CustomEventsKeys>;
  const eventEmitter = CustomEventEmitter<StoreEvents>(namespace);

  Object.keys(state).forEach((key) => {
    const stateKey = key as keyof State;
    const listener = (value: typeof state[typeof stateKey]) => {
      state[stateKey] = value;
    };
    eventEmitter.on(
      key,
      listener as CustomEventsFactory<CustomEventsKeys>[string] &
        ((value: State[string]) => void),
    );
  });

  const reset = () => {
    state = { ...initialState };
  };

  const dispatch: typeof eventEmitter.emit = (type, ...details) => {
    const currentValue = (state as State)[type as keyof State];
    if (
      details.length < 2 &&
      currentValue !== undefined &&
      currentValue !== null &&
      currentValue === details[0]
    ) {
      return;
    }
    eventEmitter.emit(type, ...details);
  };

  return {
    dispatch,
    getState: () => state,
    off: eventEmitter.off,
    on: eventEmitter.on,
    onAny: eventEmitter.onAny,
    once: eventEmitter.once,
    reset,
  };
}

export default createStore;
