/*_______________________________*/
import debounce from '../../../shared/utils/debounce';
import {
  LoggerErrorEvent,
  LoggerEvent,
  LoggerInfoEvent,
  LoggerService,
} from './types';

let events: LoggerEvent[] = [];

export default (baseUrl: string, websiteId: number): LoggerService => {
  const isProduction = process.env.PRODUCTION;

  const sendEvents = (eventsToSend: LoggerEvent[]) => {
    if (events.length === 0) {
      return;
    }
    fetch(`${baseUrl}api/website/${websiteId}/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(eventsToSend),
    }).catch(() => {
      //__________________
    });
  };

  const send = debounce(
    () => {
      sendEvents(events);
      events = [];
    },
    15000,
    {
      leading: false,
    },
  );

  /*______________________________________*/
  function error(
    desc: string,
    err: unknown,
    msg: string,
    extras?: Record<string, unknown>,
  ) {
    if (!isProduction) {
      console.error(err, desc, msg, extras);
      return;
    }

    const errorEvent: LoggerErrorEvent = {
      desc,
      err: JSON.stringify(err),
      msg,
      extras,
    };

    events.push(errorEvent);

    send();
  }

  /*_____________________________________*/
  function info(desc: string, msg: string, extras?: Record<string, unknown>) {
    if (!isProduction) {
      console.log(desc, msg, extras);
      return;
    }
    const infoEvent: LoggerInfoEvent = {
      desc,
      msg,
      extras,
    };
    events.push(infoEvent);
    send();
  }

  return {
    error,
    info,
  };
};
