/*_______________________________*/
/*__________________________________________________________________________________________________________________________________________________________________________________________________________________*/

//________________________________________________________________
type CustomEventMap = Record<string, (...args: any[]) => void>;

const allAnyListeners: Record<string, Set<Function>> = {};
const allListeners: Record<string, Record<string, Set<Function>>> = {};

export default function CustomEventEmitter<CustomEvents extends CustomEventMap>(
  namespace: string,
) {
  type CustomEventFn = <CE extends keyof CustomEvents>(
    type: CE,
    ...detail: Parameters<CustomEvents[CE]>
  ) => void;

  const anyListeners = allAnyListeners[namespace] || new Set();
  const listeners = (allListeners[namespace] || {}) as Record<
    keyof CustomEvents,
    Set<Function>
  >;
  allListeners[namespace] = listeners;

  const emit = <CE extends keyof CustomEvents>(
    type: CE,
    ...detail: Parameters<CustomEvents[CE]>
  ) => {
    if (type === undefined) {
      return;
    }

    listeners[type]?.forEach((fn) => {
      fn(...detail);
    });

    anyListeners.forEach((fn) => fn(type, ...detail));

    if (process.env.NODE_ENV === 'development') {
      console.log(
        `%c[${namespace}.event]`,
        'font-weight:bold;color:#bbb;',
        type,
        detail,
      );
    }
  };

  const on = <CE extends keyof CustomEvents>(
    type: CE,
    fn: CustomEvents[CE],
  ) => {
    listeners[type] = listeners[type] || new Set();
    listeners[type].add(fn);
  };

  const off = <CE extends keyof CustomEvents>(
    type: CE,
    fn: CustomEvents[CE],
  ) => {
    listeners[type]?.delete(fn);
  };

  const once = async <CE extends keyof CustomEvents>(
    type: CE,
    fn?: CustomEvents[CE],
  ): Promise<Parameters<CustomEvents[CE]>> => {
    return new Promise((resolve) => {
      const resolver = ((...args: Parameters<CustomEvents[CE]>) => {
        off(type, resolver);
        (() => (fn ? fn(...args) : resolve(args)))();
      }) as CustomEvents[CE];

      on(type, resolver);
    });
  };

  const onAny = (fn: CustomEventFn) => {
    anyListeners.add(fn);
  };

  return {
    emit,
    off,
    on,
    onAny,
    once,
  };
}
//_________________________
//___________________________________________________________
class Wrapper<CustomEvents extends CustomEventMap> {
  wrapped(ns: string) {
    return CustomEventEmitter<CustomEvents>(ns);
  }
}

export type Dispatcher<CustomEvents extends CustomEventMap> = ReturnType<
  Wrapper<CustomEvents>['wrapped']
>;
