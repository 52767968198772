import { SentryInitConfig, SentryScopeConfig } from './types';

let isSentryLoaded = false;

export type SentryLauncher = {
  initSentry: (config: SentryInitConfig) => void;
  configureSentry: (options: SentryScopeConfig) => void;
  sentryCatchCustomErrors: (error: Error) => string;
  getIsSentryLoaded: () => boolean;
  shouldLoadSentry: (volume: number) => boolean;
};

export const getSentryChunk = () =>
  import(/*__________________________________*/ './sentry');

export const shouldLoadSentry = (volume: number) => {
  const isProduction = process.env.PRODUCTION;
  const hasVolumeConf = typeof volume === 'number' && volume !== 0;
  //__________________________________________________
  const isSentryAlreadyLoaded = window?.__SENTRY__ !== undefined;

  return (
    !isSentryAlreadyLoaded &&
    isProduction &&
    hasVolumeConf &&
    Math.random() < 1 / volume
  );
};

export const getIsSentryLoaded = () => isSentryLoaded;

export function sentryCatchCustomErrors(error: Error) {
  getSentryChunk().then(({ catchCustomErrors }) => {
    catchCustomErrors(error);
  });
}

export function initSentry(config: SentryInitConfig) {
  isSentryLoaded = true;
  getSentryChunk().then(({ init }) => {
    init(config);
  });
}

export function configureSentry(options: SentryScopeConfig) {
  getSentryChunk().then(({ configure }) => {
    configure(options);
  });
}

export default {
  initSentry,
  configureSentry,
  sentryCatchCustomErrors,
  getIsSentryLoaded,
  shouldLoadSentry,
} as SentryLauncher;
