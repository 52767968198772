import { publicGettableProperties } from '.';
import {
  PublicGettableProperty,
  PublicMethodsCallbacks,
  PublicPropertiesStore,
  PublicPropertiesState,
} from '../../../shared/types/publicMethods';
import createStore from '../../../shared/utils/createStore';
import { getUseExplicitCookiesConsent } from '../../tagConfig';
import { registerGetter } from '../get/get';

const publicPropertiesState: PublicPropertiesState = {
  'app:boundaries': {
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  },
  'chatbox:status': 'CLOSED',
  'conversation:id': null,
  'tag:version': 'FULL',
  'visitor:cookiesConsent': getUseExplicitCookiesConsent() ? null : true,
  'visitor:GDPRConsent': null,
  'visitor:sourceId': null,
  'engagementRule:triggered': null,
  'engagementRules:triggered': [],
  'engagementNotification:displayed': null,
  'engagementNotifications:displayed': [],
};

let publicPropertiesStore = {} as PublicPropertiesStore;

export const registerGettablePublicProperties = () => {
  publicGettableProperties.forEach((property: PublicGettableProperty) =>
    registerGetter(
      property,
      (() =>
        publicPropertiesStore.getState()[
          property
        ]) as PublicMethodsCallbacks[typeof property],
    ),
  );
};

const registerPublicProperties = () => {
  publicPropertiesStore = createStore(publicPropertiesState);
};

export const getPublicPropertiesStore = () => publicPropertiesStore;

export default registerPublicProperties;
