import { ClickedUrlHandler } from '../handleClickedUrl/types';

export const getHandleClickedUrlModule = () =>
  import(
    /*____________________________________________*/ '../handleClickedUrl/handleClickedUrl'
  );

export default function labs() {
  return {
    handleClickedUrl: (clickedUrlHandler: ClickedUrlHandler) =>
      getHandleClickedUrlModule().then(({ default: handleClickedUrl }) =>
        handleClickedUrl(clickedUrlHandler),
      ),
  };
}
